export const PRODUCT_TRACKING_PAYLOAD = {
    currency: 'USD',
    value: 4.99,
    items: [
        {
            item_id: 'results',
            price: 4.99,
        },
    ],
} as const

export default function useTryTrackEvent(
    eventName: Parameters<typeof useTrackEvent>['0'],
    options: Parameters<typeof useTrackEvent>['1']
) {
    const callback =
        options && 'event_callback' in options
            ? options.event_callback
            : undefined

    // GTM blocked by user / adblocker
    if (!window.google_tag_manager) {
        callback?.()

        return
    }

    useTrackEvent(eventName, options)
}
