import { captureException, setUser } from '@sentry/nuxt'

export default defineNuxtRouteMiddleware(async (to) => {
    const auth = useAuth()

    try {
        await auth.check()
    } catch (e) {
        captureException(e)
    }

    setUser(auth.user ? { email: auth.user.email, id: auth.user.id } : null)

    if (!auth.user && to.name !== 'index' && to.meta.auth?.redirect !== false) {
        return navigateTo('/', { replace: true })
    }
})
