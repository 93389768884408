import { captureMessage } from '@sentry/nuxt'
import AuthService, { type CurrentUser } from '~/utils/services/AuthService'
import TestService from '~/utils/services/TestService'

export const useAuth = defineStore('auth', () => {
    const authService = new AuthService()
    const testService = new TestService()

    const user = ref<CurrentUser | null>(null)

    async function check() {
        const req = await authService.getUser()

        user.value = req.data.value
    }

    async function restartTest() {
        user.value!.lastTestResults = null

        await testService.restart()
    }

    async function restartChapter() {
        return testService.restartChapter()
    }

    async function logout() {
        user.value = null
        await authService.logout()
    }

    const hasTestResults = computed(() =>
        Boolean(user.value?.lastTestResults?.scores)
    )

    const testResults = computed(() => user.value?.lastTestResults)
    const finishedTestResults = computed(() =>
        user.value?.lastTestResults?.scores ? user.value?.lastTestResults : null
    )

    const hasUnfinishedTest = computed(
        () =>
            user.value &&
            Boolean(user.value.lastTestResults?.activeStepId) &&
            !user.value.lastTestResults?.scores
    )

    const paidUser = computed(() =>
        user.value && 'email' in user.value ? user.value : null
    )

    return {
        _user: user,
        user: paidUser,
        hasTestResults,
        testResults,
        finishedTestResults,
        hasUnfinishedTest,

        check,
        restartTest,
        restartChapter,
        logout,
    }
})
