import type { UseFetchOptions } from '#app'

const CSRF_COOKIE = 'XSRF-TOKEN'

async function initCsrf() {
    const existingToken = useCookie(CSRF_COOKIE).value

    if (existingToken) {
        return existingToken
    }

    await $fetch(apiUrl('sanctum/csrf-cookie'), {
        credentials: 'include',
    })

    return useCookie(CSRF_COOKIE).value
}

export function useApi<T>(
    url: string | (() => string),
    options: UseFetchOptions<T> = {}
) {
    const defaults: UseFetchOptions<T> = {
        async onRequest({ options }) {
            let token = useCookie(CSRF_COOKIE).value

            if (!token && process.client) {
                token = await initCsrf()
            }

            let headers: any = {
                accept: 'application/json',
                ...options?.headers,
                ...(token && { ['X-XSRF-TOKEN']: token }),
            }

            if (process.server) {
                const config = useRuntimeConfig()
                const cookieString = useRequestHeaders(['cookie']).cookie

                headers = {
                    ...headers,
                    ...(cookieString && { cookie: cookieString }),
                    referer: config.public.baseUrl,
                }
            }

            options.headers = headers
        },
        credentials: 'include',
        server: !useRuntimeConfig().public.useMockApi,
    }

    return useFetch(url, { ...defaults, ...options })
}
