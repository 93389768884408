import { useScroll } from '@vueuse/core'

/**
 * iOS quirk fix.
 * On some iOS devices, the initial scrollTo(0, 0) that happens between route changes,
 * will not actually scroll to the very top on iOS
 * Seems to layout height 100vh calculations.
 * Instead of trying to modify the default scrollBehavior of vue-router/nuxt,
 * we just scroll to top again after the page transition is finished.
 */
export default defineNuxtPlugin(async (nuxtApp) => {
    nuxtApp.hook('page:transition:finish', async () => {
        const scroll = useScroll(window, { behavior: 'smooth' })
        setTimeout(() => {
            if (scroll.y.value > 0) {
                setTimeout(() => {
                    scroll.y.value = -1 // iOS quirk. Will ignore scrolling to 0, but will scroll to top on <0...
                }, 0)
            }
        }, 100)
    })
})
