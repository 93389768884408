export const layoutCssClass = () => useState('layoutCssClass', () => '')

export function useLayoutCssClass(cssClass: MaybeRef<string>) {
    const mounted = ref(false)
    const resolved = layoutCssClass()

    onMounted(() => {
        resolved.value = toValue(cssClass)
        mounted.value = true
    })

    onBeforeUnmount(() => {
        resolved.value = ''
    })

    watch(
        () => toValue(cssClass),
        (value) => {
            resolved.value = toValue(value)
        }
    )

    return { mounted }
}
