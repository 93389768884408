import type { ChapterName } from '~/utils/types/types'

export const TOTAL_CHAPTERS = 7 as const

export type CurrentQuestion = {
    resultKey: string
    chapter: {
        id: number
        number: number
        name: string
        description: string
    }
    question: {
        id: number
        content: string[]
        answers: {
            id: number
            content: string[]
        }[]
        final: boolean
    }
}

export default class TestService {
    restart() {
        return useApi<CurrentQuestion>(apiUrl('quiz/reset'), {
            method: 'POST',
        })
    }
    restartChapter() {
        return useFetch<CurrentQuestion>(apiUrl('test/restart-chapter'), {
            method: 'POST',
        })
    }
    getCurrentQuestion() {
        return useApi<CurrentQuestion>(apiUrl('quiz'), {
            default() {
                return {
                    chapter: {
                        id: 0,
                        number: 1,
                        name: '',
                        description: '',
                    },
                    question: {
                        id: 0,
                        content: [],
                        answers: [],
                        final: false,
                    },
                }
            },
            transform(data) {
                // placeholder for now
                return {
                    ...data,
                    chapter: {
                        ...data.chapter,
                        description:
                            'Chapter description goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
                    },
                }
            },
        })
    }
    answer(answerId: MaybeRef<number | null | undefined>) {
        return useApi<CurrentQuestion>(apiUrl('quiz/save'), {
            method: 'POST',
            body: {
                answerId,
            },
            immediate: false,
            watch: false,
            transform(data) {
                // placeholder for now
                return {
                    ...data,
                    chapter: {
                        ...data.chapter,
                        description:
                            'Chapter description goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
                    },
                }
            },
        })
    }
    sendResults(
        email: MaybeRef<string>,
        resultsKey: MaybeRef<string>,
        subscribe: MaybeRef<boolean>
    ) {
        return useApi(apiUrl('quiz/results/send'), {
            method: 'POST',
            body: {
                email,
                key: resultsKey,
                subscribe,
            },
            immediate: false,
            watch: false,
        })
    }
    async getChapterImage(chapter: ChapterName) {
        return {
            'Just a Regular Day at Work': (
                await import('~/assets/images/chapter-1.webp')
            ).default,
            Meetings: (await import('~/assets/images/chapter-2.webp')).default,
            'Rooftop Party': (await import('~/assets/images/chapter-3.webp'))
                .default,
            'At the Museum': (await import('~/assets/images/chapter-4.webp'))
                .default,
            'A Not-So-Boring Week': (
                await import('~/assets/images/chapter-5.webp')
            ).default,
            'Philosophy and Wine': (
                await import('~/assets/images/chapter-6.webp')
            ).default,
            'New Beginnings': (await import('~/assets/images/chapter-7.webp'))
                .default,
            Reunion: (await import('~/assets/images/chapter-8.webp')).default,
        }[chapter]
    }
    getChapterDecorationShape(chapter: ChapterName) {
        const map = {
            'Just a Regular Day at Work': 4,
            Meetings: 5,
            'Rooftop Party': 6,
            'At the Museum': 7,
            'A Not-So-Boring Week': 8,
            'Philosophy and Wine': 9,
            'New Beginnings': 10,
            Reunion: 2,
        } as const

        return map[chapter]
    }
}
