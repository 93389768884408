import { default as contactc1pJl5yWumMeta } from "/pages/contact.vue?macro=true";
import { default as indexzKWOlz8EPOMeta } from "/pages/index.vue?macro=true";
import { default as order_45thank_45you05mTTL0eeYMeta } from "/pages/order-thank-you.vue?macro=true";
import { default as _91token_93kxm4Mc6bftMeta } from "/pages/password-reset/[token].vue?macro=true";
import { default as preferencesPOWKjM8EQqMeta } from "/pages/preferences.vue?macro=true";
import { default as privacyJI75r48WOeMeta } from "/pages/privacy.vue?macro=true";
import { default as refunds_45and_45cancellation0loClRpAbUMeta } from "/pages/refunds-and-cancellation.vue?macro=true";
import { default as results_45_91_91key_93_93TWxO04iZrPMeta } from "/pages/results-[[key]].vue?macro=true";
import { default as termsCGSW34GVVxMeta } from "/pages/terms.vue?macro=true";
import { default as test_45chapter_45questionx4IHDgICG1Meta } from "/pages/test-chapter-question.vue?macro=true";
import { default as test_45chapterEigpVN1rWhMeta } from "/pages/test-chapter.vue?macro=true";
import { default as introyi87b2jTepMeta } from "/pages/test/intro.vue?macro=true";
import { default as _91quality_93_45trait_45tendency7rODxWiCqMMeta } from "/pages/theory/[quality]-trait-tendency.vue?macro=true";
import { default as indexiAgLJhKl0KMeta } from "/pages/theory/index.vue?macro=true";
import { default as unsubscribemhO4AbLsVBMeta } from "/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    meta: contactc1pJl5yWumMeta || {},
    component: () => import("/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "order-thank-you",
    path: "/order-thank-you",
    meta: order_45thank_45you05mTTL0eeYMeta || {},
    component: () => import("/pages/order-thank-you.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93kxm4Mc6bftMeta || {},
    component: () => import("/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "preferences",
    path: "/preferences",
    component: () => import("/pages/preferences.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyJI75r48WOeMeta || {},
    component: () => import("/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "refunds-and-cancellation",
    path: "/refunds-and-cancellation",
    meta: refunds_45and_45cancellation0loClRpAbUMeta || {},
    component: () => import("/pages/refunds-and-cancellation.vue").then(m => m.default || m)
  },
  {
    name: "results-key",
    path: "/results-:key?",
    meta: results_45_91_91key_93_93TWxO04iZrPMeta || {},
    component: () => import("/pages/results-[[key]].vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsCGSW34GVVxMeta || {},
    component: () => import("/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "test-chapter-question",
    path: "/test-chapter-question",
    meta: test_45chapter_45questionx4IHDgICG1Meta || {},
    component: () => import("/pages/test-chapter-question.vue").then(m => m.default || m)
  },
  {
    name: "test-chapter",
    path: "/test-chapter",
    meta: test_45chapterEigpVN1rWhMeta || {},
    component: () => import("/pages/test-chapter.vue").then(m => m.default || m)
  },
  {
    name: "test-intro",
    path: "/test/intro",
    meta: introyi87b2jTepMeta || {},
    component: () => import("/pages/test/intro.vue").then(m => m.default || m)
  },
  {
    name: "theory-quality-trait-tendency",
    path: "/theory/:quality()-trait-tendency",
    meta: _91quality_93_45trait_45tendency7rODxWiCqMMeta || {},
    component: () => import("/pages/theory/[quality]-trait-tendency.vue").then(m => m.default || m)
  },
  {
    name: "theory",
    path: "/theory",
    meta: indexiAgLJhKl0KMeta || {},
    component: () => import("/pages/theory/index.vue").then(m => m.default || m)
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    meta: unsubscribemhO4AbLsVBMeta || {},
    component: () => import("/pages/unsubscribe.vue").then(m => m.default || m)
  }
]