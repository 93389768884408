<template>
    <Component :is="is.tag" v-bind="is.attrs" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Icon } from '@/utils/types/types'

const props = defineProps({
    icon: {
        type: [String, Object] as PropType<Icon>,
        required: true,
    },
    weight: {
        type: String as PropType<'normal' | 'thin'>,
        default: 'normal',
    },
})

const is = computed(() => {
    if (typeof props.icon === 'string') {
        return {
            tag: 'img',
            attrs: {
                src: props.icon,
            },
        }
    }

    return {
        tag: props.icon,
        attrs: { weight: props.weight },
    }
})
</script>
