import type { UseFetchOptions } from '#app'

export type TestResults = {
    id: number
    scores: number[] | null
    activeStepId: number | null
    key: string
    paid: boolean
}

export type AuthUser = {
    id: number
    email: string
    lastTestResults: TestResults | null
}

export type GuestUser = {
    id: string
    lastTestResults: TestResults | null
}

export type CurrentUser = AuthUser | GuestUser

export default class AuthService {
    getUser() {
        return useApi<CurrentUser>(apiUrl('me'), {})
    }
    login(
        email: Ref<string>,
        password: Ref<string>,
        onResponseError?: UseFetchOptions<any>['onResponseError']
    ) {
        return useApi<CurrentUser>(apiUrl('login'), {
            method: 'POST',
            body: {
                email,
                password,
            },
            onResponseError,
            immediate: false,
            watch: false,
        })
    }
    forgotPassword(email: Ref<string>) {
        return useApi(apiUrl('forgot-password'), {
            method: 'POST',
            body: {
                email,
            },
            immediate: false,
            watch: false,
        })
    }
    resetPassword(
        email: string,
        password: Ref<string>,
        token: string,
        onResponseError: UseFetchOptions<any>['onResponseError']
    ) {
        return useApi(apiUrl('reset-password'), {
            method: 'POST',
            body: {
                password,
                email,
                token,
            },
            immediate: false,
            watch: false,
            onResponseError,
        })
    }
    changePassword(
        oldPassword: Ref<string>,
        newPassword: Ref<string>,
        onResponseError: UseFetchOptions<any>['onResponseError']
    ) {
        return useApi(apiUrl('update-password'), {
            method: 'POST',
            body: {
                oldPassword,
                newPassword,
            },
            immediate: false,
            watch: false,
            onResponseError,
        })
    }
    changeEmail(
        newEmail: Ref<string>,
        onResponseError: UseFetchOptions<any>['onResponseError']
    ) {
        return useApi(apiUrl('update-email'), {
            method: 'POST',
            body: {
                email: newEmail,
            },
            immediate: false,
            watch: false,
            onResponseError,
        })
    }
    deleteProfile(onResponseError?: UseFetchOptions<any>['onResponseError']) {
        return useApi(apiUrl('delete-profile'), {
            method: 'POST',
            immediate: false,
            watch: false,
            onResponseError,
        })
    }
    logout() {
        return useApi(apiUrl('logout'), {
            method: 'POST',
        })
    }
    orderCheckout(testResultsKey: string) {
        return useApi<{ url: string }>(apiUrl('orders/checkout'), {
            method: 'GET',
            onResponse({ response }) {
                if (response._data.url) {
                    useTryTrackEvent('begin_checkout', {
                        ...PRODUCT_TRACKING_PAYLOAD,
                        event_callback: () =>
                            navigateTo(response._data.url, {
                                external: true,
                            }),
                    })
                }
            },
            params: {
                test_results_key: testResultsKey,
            },
            immediate: false,
        })
    }
    completeOrder(key: string) {
        return useApi<{ testResultKey: string }>(apiUrl('orders/thank-you'), {
            method: 'POST',
            server: false,
            body: {
                key,
            },
            immediate: false,
        })
    }
    unsubscribe(email: string) {
        return useApi(apiUrl('quiz/unsubscribe'), {
            method: 'POST',
            body: {
                email,
            },
        })
    }
}
