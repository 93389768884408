export default defineNuxtPlugin(async (nuxt) => {
    if (
        process.env.NODE_ENV === 'production' ||
        nuxt.$config.public.useMockApi !== true
    ) {
        return
    }

    /*const { worker } = await import('@/mocks/browser')

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    await worker.start({ onUnhandledRequest: 'bypass' })*/
})
